import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { observer } from "mobx-react-lite";
import { DraggableBlock } from "./DraggableBlock";
import { AnchorBlock } from "./AnchorBlock";
import { useStore } from "structure";
import { Button } from "semantic-ui-react";
import { Icon } from "assets/icons/Icon";
import { T } from "util/Translation";

export const Droppable = observer(({ id, setView }) => {
  const { formEditorStore } = useStore();
  const { setNodeRef } = useDroppable({
    id,
  });
  return (
    <SortableContext
      id={id}
      items={formEditorStore.blocksOrder}
      strategy={verticalListSortingStrategy}
    >
      <div ref={setNodeRef} className="ls-blocks">
        {formEditorStore.blocksOrder.map((blockId) => (
          <DraggableBlock
            blockId={blockId}
            key={`block-${blockId}`}
            setView={setView}
          />
        ))}
        <AnchorBlock id="end" />
        <div className="ls-add-block">
          <Button
            className="ls-bright ls-with-icon"
            primary
            fluid
            onClick={() => setView("build")}
            type="button"
          >
            <Icon name="plus" />
            <T id="interface.mobile.add_block" />
          </Button>
          <Button
            className="ls-bright ls-with-icon"
            secondary
            fluid
            type="submit"
          >
            <Icon name="save" />
            <T id="interface.mobile.save_changes" />
          </Button>
        </div>
      </div>
    </SortableContext>
  );
});
