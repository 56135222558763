import { Button, Modal } from "semantic-ui-react";
import { Icon } from "assets/icons/Icon";
import { useState } from "react";
import { useStore } from "structure";
import { Confirm } from "ui/Confirm";
import { toast } from "react-toastify";
import { API_URL } from "util/consts";

import { useTranslation } from "react-i18next";

import * as S from "./styles";

export const MediaItem = ({
  item,
  handleImageClick,
  selectedImage,
  selectedImages = [],
}) => {
  const { t } = useTranslation();
  const { mediaStore } = useStore();
  const [open, setOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  return (
    <>
      <S.MediaItemWrapper
        className={
          selectedImage === item || selectedImages.includes(item)
            ? "ls-active"
            : null
        }
        onClick={() =>
          handleImageClick ? handleImageClick(item) : setOpen(true)
        }
      >
        <img
          src={`${API_URL}${item}`}
          alt="media-item"
          style={{
            animation: "fadeIn 0.3s",
          }}
          loading="lazy"
        />
        {!handleImageClick && (
          <div className="media-actions" onClick={(e) => e.stopPropagation()}>
            <Button
              className="ls-bright ls-icon"
              color="grey"
              onClick={() => setOpen(true)}
            >
              <Icon name="openInFull" />
            </Button>

            <Button
              className="ls-bright ls-icon"
              color="grey"
              onClick={() => setIsDeleteModalOpen(true)}
            >
              <Icon name="delete" />
            </Button>
          </div>
        )}
      </S.MediaItemWrapper>
      <Confirm
        title={t("media_modal.media_item.delete_title")}
        text={t("media_modal.media_item.delete_confirmation")}
        confirmButton={t("media_modal.media_item.delete_confirm_button")}
        color="red"
        open={isDeleteModalOpen}
        onCancel={() => setIsDeleteModalOpen(false)}
        onConfirm={() =>
          mediaStore
            .removeImage(item)
            .then(() =>
              toast.success(t("media_modal.media_item.delete_success"))
            )
            .catch(() => toast.error(t("media_modal.media_item.delete_error")))
        }
      />
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        closeOnDimmerClick
        style={{
          background: "transparent",
          boxShadow: "none",
          width: "auto",
        }}
      >
        <button
          onClick={() => setOpen(false)}
          style={{
            position: "absolute",
            right: -40,
            padding: 8,
            background: "transparent",
            borderRadius: 16,
            fill: "var(--grey-50)",
            border: "none",
          }}
        >
          <Icon
            name="close"
            height={24}
            width={24}
            style={{ height: 24, width: 24 }}
          />
        </button>

        <img
          src={`${API_URL}${item}`}
          alt="media-full-size"
          style={{
            borderRadius: 16,
            maxHeight: "90dvh",
            maxWidth: "90vw",
            animation: "fadeIn 0.3s",
          }}
        />
      </Modal>
    </>
  );
};
