import styled from "styled-components";
import { basicTemplate } from "./templates/basic";
import { shadeColor } from "util/helpers";

export const Container = styled.div`
  ${({ color }) => fillColor("primary", color)}
  display: flex;
  flex-direction: column;
  gap: 32px;
  animation: fadeIn 0.3s;

  a {
    color: var(--primary-600);
    :hover {
      color: var(--primary-800);
    }
  }

  > form {
    width: min(760px, calc(100% - 64px));
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: flex-start;
    gap: 48px;
    margin-bottom: 48px;
  }

  .lf-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    textarea,
    .ui.dropdown,
    .ui.input {
      width: auto;
      min-width: min(400px, 100%);
    }
  }

  .ls-actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  > header {
    padding: 16px 0;
    > div {
      width: calc(100% - 64px);
      margin: 0 auto;
    }
  }

  > footer {
    .ls-footer {
      padding: 8px 0;
      width: calc(100% - 64px);
      margin: 0 auto;
      padding-bottom: 8px;
    }

    .ls-lumo-pages-footer {
      display: flex;
      padding: 8px 32px;
      align-items: center;
      justify-content: space-between;
      background: var(--grey-100);
      border-top: 1px solid var(--grey-300);
      > .lp-logo {
        margin-bottom: -8px;
        display: flex;
        align-items: flex-start;
        span {
          line-height: 32px;
        }
      }
      a {
        color: var(--secondary-600);
        :hover {
          color: var(--secondary-800);
        }
      }

      ul {
        display: flex;
        align-items: center;
        gap: 28px;
        margin: 0;
        list-style-color: var(--grey-400);

        li {
          color: var(--grey-400);
          :first-child {
            color: var(--grey-700);
            list-style-type: none;
          }
        }
      }
    }
  }

  ${basicTemplate}
`;

const fillColor = (name, base) => {
  let res = `--${name}-50: ${shadeColor(base, 95)};`;
  for (let i = 1; i <= 9; i++) {
    res += `--${name}-${i * 100}: ${shadeColor(base, 100 - i * 20)};`;
  }
  return res;
};
