import styled from "styled-components";
import { scrollElement } from "ui/common-styles";

export const GalleryWrapper = styled.div`
  background: white;
  border-radius: 16px;
  margin-right: 8px;

  input[type="file"]::file-selector-button {
    border-radius: 4px;
    padding: 0 16px;
    height: 40px;
    cursor: pointer;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.16);
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    margin-right: 16px;
    transition: background-color 200ms;
  }

  /* file upload button hover state */
  input[type="file"]::file-selector-button:hover {
    background-color: #f3f4f6;
  }

  /* file upload button active state */
  input[type="file"]::file-selector-button:active {
    background-color: #e5e7eb;
  }

  .gallery-grid {
    display: flex;
    gap: 24px;
    padding: 16px;
    flex-direction: row;
    flex-wrap: wrap;
    max-height: 60dvh;
    overflow-y: auto;

    ${scrollElement}
  }

  .gallery-upload {
    padding: 16px;
    border-top: 1px solid var(--grey-300);
  }
`;

export const MediaItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 200px;
  width: 200px;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;

  &.ls-active {
    outline: 4px solid var(--tertiary-500);
  }

  img {
    height: 100%;
    width: 100%;
    min-height: 200px;
    min-width: 200px;
    object-fit: cover;
  }

  .media-actions {
    position: relative;
    background: transparent;
    height: 60px;
    width: 100%;
    padding: 10px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 4px;
    fill: white;
    transition: 0.3s;
  }

  :hover > .media-actions {
    bottom: 68px;
    background: linear-gradient(0deg, #1c2628, transparent);
  }

  @media (max-width: 700px) {
    height: 100%;
    width: 100%;
    aspect-ratio: 1;
  }
`;

export const GalleryMediaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  > p {
    margin: 24px 16px 0;
    font-size: 1.2em;
  }

  > div {
    margin: 0;
  }
`;
