import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .ls-title-with-action {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    margin-bottom: 32px;

    h3 {
      margin-bottom: 0px;
    }
  }

  .ls-actions {
    width: 100%;
    display: flex;
    justify-content: space-between;

    ul {
      display: flex;
      list-style-type: none;
      gap: 16px;
      margin: 0px;
      border-bottom: 1px solid var(--grey-500);
      padding: 0 8px 8px;
      margin-bottom: -8px;
    }
  }

  h3.project-name {
    text-wrap: nowrap;
    font-size: clamp(32px, 8vw, 60px);
    color: var(--secondary-500);
    text-transform: capitalize;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
    animation: fadeIn 0.3s both;
  }
  .project-description {
    margin-top: -16px;
    font-size: 18px;
    color: ;
  }

  .project-details {
    margin-top: -16px;
    ul {
      list-style-type: none;
      padding-inline-start: 0;
      flex-wrap: wrap;
      display: flex;
      gap: 16px 24px;

      li {
        display: flex;
        flex-direction: column;
        background: var(--grey-100);
        padding: 8px 32px 8px 16px;
        border-radius: 8px;
        min-width: 200px;
        border: 1px solid var(--grey-700);
        overflow: hidden;

        &.ls-status {
          min-width: 0;
          padding: 8px 16px;
          display: flex;
          align-items: center;
          gap: 8px;

          &.published {
            background: #27a652;
            border: 1px solid #0a4920;
            color: white;
          }
        }

        .detail-field {
          font-size: 16px;
        }
        .detail-value {
          font-size: 18px;
          font-weight: 600;

          .ls-status {
            display: flex;
            align-items: center;
            gap: 8px;

            svg {
              height: 16px;
              width: 16px;
            }
          }

          .detail-value-hovered {
            opacity: 0;
            position: relative;
            height: 0;
            width: 100%;
            transition: 0.3s;

            > button {
              height: 70px;
              position: relative;
              top: -58px;
              left: -20px;
              width: calc(100% + 72px);
              display: flex;
              align-items: center;
              justify-content: center;
              background: #ffffff88;
              font-weight: 700;
              color: var(--primary);
              opacity: 1;
              font-size: 18px;
            }
          }
        }
        :hover {
          .detail-value-hovered {
            opacity: 1;
          }

          > *:not(.detail-value),
          > .detail-value > *:not(.detail-value-hovered) {
            filter: blur(4px);
          }
        }
      }
    }
  }

  .project-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 140px;
    background: linear-gradient(to left, var(--grey-100), var(--grey-200));
    padding: 0 40px;
  }

  .ui.secondary.pointing.menu {
    margin: 14px 40px;
    border: none;
    gap: 16px;

    .item {
      border: 1px solid var(--grey-200);
      background: var(--white);
      border-radius: 8px;
      padding: 8px 16px;
      display: flex;
      gap: 8px;
      transition: 0.2s;

      svg {
        height: 16px;
        width: 16px;
        fill: var(--grey-600);
      }

      span {
        font-family: var(--title-font);
        font-weight: 700;
        color: var(--grey-600);
        line-height: 20px;
      }

      :hover {
        background: var(--white);
        border-color: var(--primary-700);
        svg {
          fill: var(--primary-700);
        }

        span {
          color: var(--primary-700);
        }
      }

      &.active {
        color: var(--primary-800);
        border: 1px solid var(--primary-700);
        box-shadow: 0px 2px 8px #155e7548;

        svg {
          fill: var(--primary-700);
        }

        span {
          color: var(--primary-700);
        }
      }
    }
  }

  @media (max-width: 700px) {
    header {
      max-height: 0px;
      min-height: 0px;
      border: none;
    }

    .project-section {
      justify-content: center;
    }

    .ui.secondary.pointing.menu {
      position: relative;
      margin: 40px 0 0;
      padding: 8px 16px;
      overflow-x: auto;
      border-bottom: 1px solid var(--primary-700);
      box-shadow: rgba(21, 94, 117, 0.282) 0px 2px 8px;
      scrollbar-width: none;
      z-index: 1;

      .item {
        border: none;
        &.active {
          border: none;
          box-shadow: none;
        }
      }

      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
`;

export const PreviewHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 32px;
  position: fixed;
  top: 0;
  background: var(--grey-700);
  width: 100%;
  border-bottom: 1px solid var(--grey-200);

  > p {
    margin: 0;
    color: var(--grey-100);
    font-size: 18px;
    font-family: var(--title-font);
    font-weight: 700;
  }

  > a {
    fill: var(--grey-200);
    color: var(--grey-200);
    text-decoration: none;

    :hover {
      color: var(--grey-50);
    }
  }
`;

export const SidebarWrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .ls-sidebar-items {
    list-style-type: none;
    padding-inline-start: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;

    > li {
      > span:first-child {
        display: inline-block;
        min-width: 140px;
        font-weight: 500;
      }
    }
  }
  .ls-sidebar-field-items {
    list-style-type: none;
    padding-inline-start: 0;
    display: flex;
    flex-direction: column;
    padding: 16px 0;
  }

  .ls-content {
    padding: 48px 24px;
    overflow-y: scroll;
  }

  .ls-actions {
    padding: 16px 24px;
    border-top: 1px solid var(--grey-500);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }

  .ui.input.ls-color-picker > input {
    padding: 0px;
    max-width: 60px;
    border: none;
  }
`;

export const ResponsePreviewWrapper = styled.div`
  width: min(760px, calc(100% - 64px));
  margin: 120px auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 32px;

  ul {
    display: flex;
    flex-direction: column;
    gap: 16px;
    list-style-type: none;
    padding-inline-start: 0;

    > li {
      display: flex;
      flex-direction: column;
      gap: 4px;

      p {
        margin: 0;
        overflow-wrap: anywhere;

        :first-child {
          font-weight: 700;
        }
      }
    }
  }
`;
