import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 99dvh;
  justify-content: center;
  gap: 8px;

  strong {
    color: var(--secondary-500);
  }

  .ui.button {
    margin: 24px 0;
  }
`;
