import { observer } from "mobx-react-lite";
import { PanelTemplate } from "../PanelTemplate";
import { Slug } from "./content/Slug";
import { Info } from "./content/Info";
import { Description } from "./content/Description";
import { Name } from "./content/Name";
import { Publish } from "./content/Publish";

import { T } from "util/Translation";
import { useTranslation } from "react-i18next";

export const Overview = observer(({ project, updateSettings }) => {
  const { t } = useTranslation();
  const projectSettings = project.settings;

  const pages = [
    {
      name: t("overview.project_name"),
      value: project?.name || t("overview.untitled"),
      content: <Name project={project} />,
    },
    {
      name: t("overview.description"),
      value: projectSettings?.desc || t("overview.no_description"),
      content: (
        <Description project={project} updateSettings={updateSettings} />
      ),
    },
    {
      name: t("overview.slug"),
      value: project?.slug || t("overview.unknown"),
      content: <Slug project={project} updateSettings={updateSettings} />,
    },
    {
      name: t("overview.publish_status"),
      value: project?.isPublic ? (
        <span className="ls-tag ls-active">
          <T id="overview.public" />
        </span>
      ) : (
        <span className="ls-tag">
          <T id="overview.draft" />
        </span>
      ),
      content: <Publish project={project} />,
    },
    {
      name: t("overview.project_info"),
      value: t("overview.creation_time"),
      content: <Info project={project} />,
    },
  ];

  return <PanelTemplate pages={pages} projectName={project.name} />;
});
