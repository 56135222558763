import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStore } from "structure";
import { useForm } from "react-final-form";

import { Button } from "semantic-ui-react";
import { Icon } from "assets/icons/Icon";
import { ContentElementSection } from "./ContentElementSection";

import { T } from "util/Translation";

import * as S from "./styles";
import { useTranslation } from "react-i18next";

export const BlockSettings = observer(({ className }) => {
  const { t } = useTranslation();
  const { formEditorStore } = useStore();
  const formaRef = useForm();
  const { currentBlockEdit, blockEditId, currentBlockType, currentBlockTool } =
    formEditorStore;

  const blockId = blockEditId;
  const type = currentBlockType;
  const tool = currentBlockTool;

  const handleRemove = () => {
    formEditorStore.remove(`block-${blockId}`);
  };

  useEffect(() => {
    if (blockId && !formaRef.getState().values[blockId]) {
      formaRef.change(`${blockId}.tool-${tool?.id}`, {});
    }
    // eslint-disable-next-line
  }, []);

  const handleDuplicate = async () => {
    const newBlockId = await formEditorStore.duplicate(blockId);
    if (newBlockId) {
      const values = formaRef?.getState()?.values[blockId] || {};
      formaRef.change(newBlockId, values);
      formEditorStore.setBlockEditId(newBlockId);
    }
  };

  if (currentBlockEdit && !currentBlockEdit.deleted) {
    return (
      <S.Wrapper key={blockId} className={className}>
        <div className="ls-header">
          <Icon name={tool.icon} />
          <h4>
            <T id="block_settings.title" values={{ toolName: t(tool.name) }} />
          </h4>
        </div>
        <ContentElementSection blockId={blockId} type={type} />

        <div className="ls-section">
          <h6>
            <T id="block_settings.actions" />
          </h6>
          <ul>
            <li>
              <Button
                type="button"
                compact
                fluid
                className="ls-bright ls-split"
                color="grey"
                onClick={handleDuplicate}
              >
                <span>
                  <T id="block_settings.duplicate_block" />
                </span>
                <Icon name="duplicate" height={18} />
              </Button>
            </li>
            <li>
              <Button
                type="button"
                compact
                fluid
                className="ls-bright ls-split"
                color="red"
                onClick={handleRemove}
              >
                <span>
                  <T id="block_settings.delete_block" />
                </span>
                <Icon name="delete" height={18} />
              </Button>
            </li>
          </ul>
        </div>
      </S.Wrapper>
    );
  }
  return (
    <S.WrapperEmpty className={className}>
      <Icon name="inboxSettings" color="var(--grey-700)" height={48} />
      <h4>
        <T id="block_settings.empty_title" />
      </h4>
    </S.WrapperEmpty>
  );
});
