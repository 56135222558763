import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
`;

export const ButtonOps = styled.div`
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  height: 0px;
  padding: 20px;
  opacity: 1;
  transition: 0.3s;
`;
