import { observer } from "mobx-react-lite";
import { PanelTemplate, ActiveTag } from "../PanelTemplate";
import { NextBtn } from "./content/NextBtn";
import { SubmitBtn } from "./content/SubmitBtn";
import { UsingSteps } from "./content/UsingSteps";
import { ThemeColor } from "./content/ThemeColor";
import { Template } from "./content/Template";
import { FrameFields } from "./content/FrameFields";

import { useTranslation } from "react-i18next";

export const Settings = observer(({ project, updateSettings }) => {
  const { t } = useTranslation();
  const projectPublicSettings = project.public_settings;
  const themeColor = projectPublicSettings.color || "#6366f1";

  const pages = [
    {
      name: t("settings.template.title"),
      value: `${
        projectPublicSettings.template || t("settings.template.default")
      }`,
      content: <Template project={project} updateSettings={updateSettings} />,
    },
    {
      name: t("settings.theme_color.title"),
      value: (
        <div
          className="ls-tag ls-inherit-bg"
          style={{ background: themeColor }}
        >
          <span style={{ filter: "invert() sepia()", fontWeight: 700 }}>
            {themeColor}
          </span>
        </div>
      ),
      content: <ThemeColor project={project} updateSettings={updateSettings} />,
    },
    {
      name: t("settings.using_steps.title"),
      value: <ActiveTag isActive={projectPublicSettings.steps} />,
      content: <UsingSteps project={project} updateSettings={updateSettings} />,
    },
    {
      name: t("settings.frame_fields.title"),
      value: <ActiveTag isActive={projectPublicSettings.frame} />,
      content: (
        <FrameFields project={project} updateSettings={updateSettings} />
      ),
    },
    {
      name: t("settings.next_btn.title"),
      value: projectPublicSettings?.nextBtn || t("settings.next_btn.default"),
      content: <NextBtn project={project} updateSettings={updateSettings} />,
    },
    {
      name: t("settings.submit_btn.title"),
      value:
        projectPublicSettings?.submitBtn || t("settings.submit_btn.default"),
      content: <SubmitBtn project={project} updateSettings={updateSettings} />,
    },
  ];

  return <PanelTemplate pages={pages} projectName={project.name} />;
});
