import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "structure";
import { Link } from "react-router-dom";

import { Loading } from "components/shared/Loading";
import { Button, Placeholder, PlaceholderLine } from "semantic-ui-react";
import { TopMenu } from "ui/common-styles";
import { Icon } from "assets/icons/Icon";

import { T } from "util/Translation";

import * as S from "./styles";

export const Payment = observer(() => {
  const { accountStore } = useStore();
  const { isLoading } = accountStore;

  if (isLoading) {
    return (
      <div style={{ height: 400 }}>
        <Loading />
      </div>
    );
  }

  return (
    <S.Wrapper>
      <TopMenu>
        <ul className="nav">
          <li>
            <Button basic className="ls-with-icon" as={Link} to={`/account`}>
              <Icon name="left" color="var(--grey-600)" />
              <T id="account.title" />
            </Button>
          </li>
        </ul>
      </TopMenu>

      <div className="main-section">
        <div className="header">
          <h1>
            <T id="payment.history.title" />
          </h1>
          <p>
            <T id="payment.history.description" />
          </p>
        </div>
        <PaymentsTable />
      </div>
    </S.Wrapper>
  );
});

const PaymentsTable = observer(() => {
  const { paymentsStore } = useStore();
  const { isLoading, payments, isEmpty } = paymentsStore;
  const [isFirstLoading, setIsFirstLoading] = useState(true);

  useEffect(() => {
    paymentsStore.fetchPayments().then(() => setIsFirstLoading(false));
    // eslint-disable-next-line
  }, []);

  if (isFirstLoading) {
    return <Loading />;
  }

  if (!isEmpty) {
    return (
      <h4>
        <T id="payment.history.no_payments" />
      </h4>
    );
  }

  return (
    <table>
      <thead>
        <tr>
          <th>
            <T id="payment.table.payment_id" />
          </th>
          <th>
            <T id="payment.table.plan" />
          </th>
          <th>
            <T id="payment.table.amount" />
          </th>
          <th>
            <T id="payment.table.start_at" />
          </th>
          <th>
            <T id="payment.table.end_at" />
          </th>
          <th>
            <T id="payment.table.invoice" />
          </th>
        </tr>
      </thead>

      <tbody>
        {isLoading ? (
          <tr>
            {[...Array(6)].map((_, idx) => (
              <td key={idx}>
                <Placeholder>
                  <PlaceholderLine />
                </Placeholder>
              </td>
            ))}
          </tr>
        ) : (
          payments.map((payment) => (
            <tr key={payment.payment_id}>
              <td>{payment.payment_id}</td>
              <td>{payment.subscription_plan}</td>
              <td>{payment.amount}</td>
              <td>{payment.payment_time}</td>
              <td>{payment.end_at}</td>
              <td>
                <Link to={payment.invoice_link}>
                  <T id="payment.table.download" />
                </Link>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
});
