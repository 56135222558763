import { observer } from "mobx-react-lite";
import { PanelTemplate } from "../PanelTemplate";
import { Email } from "./content/Email";
import { Webhook } from "./content/Webhook";

import { useTranslation } from "react-i18next";
import { T } from "util/Translation";

export const Submissions = observer(({ project, updateSettings }) => {
  const { t } = useTranslation();
  const projectSettings = project.settings;

  const pages = [
    {
      name: t("submissions.email_notification"),
      value: projectSettings?.use_reply_email ? (
        <span className="ls-tag ls-active">
          <T id="submissions.active" />
        </span>
      ) : (
        <span className="ls-tag">
          <T id="submissions.disabled" />
        </span>
      ),
      content: <Email project={project} updateSettings={updateSettings} />,
    },
    {
      name: t("submissions.webhook"),
      value: projectSettings?.use_reply_webhook ? (
        <span className="ls-tag ls-active">
          <T id="submissions.active" />
        </span>
      ) : (
        <span className="ls-tag">
          <T id="submissions.disabled" />
        </span>
      ),
      content: <Webhook project={project} updateSettings={updateSettings} />,
    },
  ];

  return <PanelTemplate pages={pages} projectName={project.name} />;
});
